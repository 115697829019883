import { AIPrompt } from "@converge-collective/common/models/AIPrompt";
import {
  ActivityLog,
  GoalActivityLog,
  GoalV2ActivityLog,
  QuizActivityLog,
  RewardRedemptionActivityLog,
} from "@converge-collective/common/models/ActivityLog";
import { AuthzGrants } from "@converge-collective/common/models/Authz";
import { WithBase, WithRef } from "@converge-collective/common/models/Base";
import { Category } from "@converge-collective/common/models/Category";
import {
  Challenge,
  ChallengeSection,
  GoalChallenge,
} from "@converge-collective/common/models/Challenge";
import {
  ChallengeBlock,
  ChallengeV2,
  CourseChallenge,
  GoalChallengeV2,
  GoalChallengeV2Template,
} from "@converge-collective/common/models/ChallengeV2";
import { Chat, ChatMessage } from "@converge-collective/common/models/Chat";
import { Doc } from "@converge-collective/common/models/Doc";
import { DocChunk } from "@converge-collective/common/models/DocChunk";
import { DocRequest } from "@converge-collective/common/models/DocRequest";
import { Event } from "@converge-collective/common/models/Event";
import { MuxVideoUpload } from "@converge-collective/common/models/MuxVideoUpload";
import { EventAttendance } from "@converge-collective/common/models/EventAttendance";
import {
  EventActivityLog,
  EventChatLog,
  EventInviteLog,
  EventLog,
} from "@converge-collective/common/models/EventLog";
import { EventOccurrence } from "@converge-collective/common/models/EventOccurrence";
import { Folder } from "@converge-collective/common/models/Folder";
import {
  CategoryBasedGroup,
  Group,
  GroupMember,
} from "@converge-collective/common/models/Group";
import { GroupLog } from "@converge-collective/common/models/GroupLog";
import { InterestLevel } from "@converge-collective/common/models/InterestLevel";
import {
  EventInvite,
  GroupInvite,
  Invite,
  JoinNetworkInvite,
} from "@converge-collective/common/models/Invite";
import {
  LeaderboardItem,
  RollupLeaderboardItem,
} from "@converge-collective/common/models/Leaderboard";
import {
  Network,
  NetworkMembership,
  NetworkType,
  PublicNetwork,
} from "@converge-collective/common/models/Network";
import { NetworkAdmin } from "@converge-collective/common/models/NetworkAdmin";
import { NetworkSettings } from "@converge-collective/common/models/NetworkSettings";
import { Notification } from "@converge-collective/common/models/Notification";
import { NotificationPreference } from "@converge-collective/common/models/NotificationPreferences";
import { OnboardingResource } from "@converge-collective/common/models/OnboardingResource";
import { PeerRecognition } from "@converge-collective/common/models/PeerRecognition";
import {
  ChallengeV2Post,
  DocPost,
  GroupCreatedPost,
  MessagePost,
  Post,
  PostComment,
} from "@converge-collective/common/models/Post";
import {
  LiteProfile,
  Profile,
} from "@converge-collective/common/models/Profile";
import { ProfilePoints } from "@converge-collective/common/models/ProfilePoints";
import { Props } from "@converge-collective/common/models/Props";
import { Question, Quiz } from "@converge-collective/common/models/Quiz";
import { Reward } from "@converge-collective/common/models/Reward";
import { Subscription } from "@converge-collective/common/models/Subscription";
import { Tag } from "@converge-collective/common/models/Tag";
import { Team, TeamMember } from "@converge-collective/common/models/Team";
import { Unfurl } from "@converge-collective/common/models/Unfurl";
import { UserClaims } from "@converge-collective/common/models/User";
import { CustomRole } from "@converge-collective/common/models/rbac/CustomRole";
import { CustomRoleAssignment } from "@converge-collective/common/models/rbac/CustomRoleAssignment";
import {
  NetworkPermission,
  NetworkPermissionOverrides,
} from "@converge-collective/common/models/rbac/NetworkPermission";
import { modelFromSnap } from "@converge-collective/common/util";
import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
import { omit } from "lodash";
import { Admin } from "~/components/admin/SuperAdminLayout";

export const converter = <T extends {}>(): FirestoreDataConverter<
  WithBase<WithRef<T>>
> => ({
  toFirestore: (
    data: PartialWithFieldValue<WithBase<WithRef<T>>>
  ): DocumentData => omit(data, "ref", "id", "NO_ID_FIELD"),
  fromFirestore: (
    snap: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): WithBase<WithRef<T>> => modelFromSnap<T>(snap, options),
});

/**
 * Use a separate converter for writing documents back into firestore because
 * the type should not include the `ref` or `id` properties (ie WithID and
 * WithRef)
 **/
export const writeConverter = <T extends {}>(): FirestoreDataConverter<T> => ({
  toFirestore: (data: WithFieldValue<T>): DocumentData =>
    // don't write the ref or id back to firestore
    omit(data, ["ref", "id", "NO_ID_FIELD"]),
  // NOTE: don't use this for reading back from firestore, use `converter` instead
  fromFirestore: (snap: QueryDocumentSnapshot, options: SnapshotOptions): T =>
    modelFromSnap<T>(snap, options),
});

const readWriteConverters = <T extends {}>() => ({
  read: converter<T>(),
  write: writeConverter<T>(),
});

export const converters = {
  activityLog: readWriteConverters<ActivityLog>(),
  admin: readWriteConverters<Admin>(),
  authzGrants: readWriteConverters<AuthzGrants>(),
  aiPrompt: readWriteConverters<AIPrompt>(),
  category: readWriteConverters<Category>(),
  categoryBasedGroup: readWriteConverters<CategoryBasedGroup>(),
  customRole: readWriteConverters<CustomRole>(),
  customRoleAssignment: readWriteConverters<CustomRoleAssignment>(),
  challenge: readWriteConverters<Challenge>(),
  challengeV2: readWriteConverters<ChallengeV2>(),
  challengeBlock: readWriteConverters<ChallengeBlock>(),
  challengeSection: readWriteConverters<ChallengeSection>(),
  courseChallenge: readWriteConverters<CourseChallenge>(),
  goalChallengeV2Template: readWriteConverters<GoalChallengeV2Template>(),
  chat: readWriteConverters<Chat>(),
  chatMessage: readWriteConverters<ChatMessage>(),
  doc: readWriteConverters<Doc>(),
  docChunk: readWriteConverters<DocChunk>(),
  docRequest: readWriteConverters<DocRequest>(),
  event: readWriteConverters<Event>(),
  eventActivityLog: readWriteConverters<EventActivityLog>(),
  eventAttendance: readWriteConverters<EventAttendance>(),
  eventChatLog: readWriteConverters<EventChatLog>(),
  eventInvite: readWriteConverters<EventInvite>(),
  eventInviteLog: readWriteConverters<EventInviteLog>(),
  eventLog: readWriteConverters<EventLog>(),
  eventOccurrence: readWriteConverters<EventOccurrence>(),
  folder: readWriteConverters<Folder>(),
  goalActivityLog: readWriteConverters<GoalActivityLog>(),
  goalV2ActivityLog: readWriteConverters<GoalV2ActivityLog>(),
  quizActivityLog: readWriteConverters<QuizActivityLog>(),
  goalChallenge: readWriteConverters<GoalChallenge>(),
  goalChallengeV2: readWriteConverters<GoalChallengeV2>(),
  group: readWriteConverters<Group>(),
  groupInvite: readWriteConverters<GroupInvite>(),
  groupLog: readWriteConverters<GroupLog>(),
  groupMember: readWriteConverters<GroupMember>(),
  interestLevel: readWriteConverters<InterestLevel>(),
  invite: readWriteConverters<Invite>(),
  joinNetworkInvite: readWriteConverters<JoinNetworkInvite>(),
  leaderboardItem: readWriteConverters<LeaderboardItem>(),
  liteProfile: readWriteConverters<LiteProfile>(),
  messagePost: readWriteConverters<MessagePost>(),
  groupCreatedPost: readWriteConverters<GroupCreatedPost>(),
  docPost: readWriteConverters<DocPost>(),
  challengeV2Post: readWriteConverters<ChallengeV2Post>(),
  muxVideoUpload: readWriteConverters<MuxVideoUpload>(),
  network: readWriteConverters<Network>(),
  networkAdmin: readWriteConverters<NetworkAdmin>(),
  networkMembership: readWriteConverters<NetworkMembership>(),
  networkSettings: readWriteConverters<NetworkSettings>(),
  networkType: readWriteConverters<NetworkType>(),
  notification: readWriteConverters<Notification>(),
  notificationPreference: readWriteConverters<NotificationPreference>(),
  onboardingResource: readWriteConverters<OnboardingResource>(),
  peerRecognition: readWriteConverters<PeerRecognition>(),
  post: readWriteConverters<Post>(),
  postComment: readWriteConverters<PostComment>(),
  profile: readWriteConverters<Profile>(),
  profilePoints: readWriteConverters<ProfilePoints>(),
  props: readWriteConverters<Props>(),
  publicNetwork: readWriteConverters<PublicNetwork>(),
  quiz: readWriteConverters<Quiz>(),
  question: readWriteConverters<Question>(),
  reward: readWriteConverters<Reward>(),
  rewardRedemptionActivityLog:
    readWriteConverters<RewardRedemptionActivityLog>(),
  rollupLeaderboardItem: readWriteConverters<RollupLeaderboardItem>(),
  subscription: readWriteConverters<Subscription>(),
  tag: readWriteConverters<Tag>(),
  team: readWriteConverters<Team>(),
  teamMember: readWriteConverters<TeamMember>(),
  userClaims: readWriteConverters<UserClaims>(),
  unfurl: readWriteConverters<Unfurl>(),
  userPermissions: readWriteConverters<NetworkPermission>(),
  networkPermissionOverrides: readWriteConverters<NetworkPermissionOverrides>(),
};
