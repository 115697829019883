import {
  basePermissionSet,
  NetworkPermissionSet,
} from "@converge-collective/common/models/rbac/NetworkPermission";
import { memo, useEffect } from "react";
import {
  useNetworkPermissions,
  useUserAssignedRoles,
} from "~/hooks/useUserPermissions";
import { useLoggedInState } from "~/lib/useLoggedInState";
import {
  HydratedLoggedInState,
  withLoggedInState,
} from "~/lib/withLoggedInState";

// importing these directly loses the type for some reason and ends up being:
// type NetworkPermissionSet = {
//     [x: string]: boolean;
// }
// until we figure out why, reconstruct the types here instead
// type PermissionKind = Values<typeof permissions>;
// type NetworkPermissionSet = Record<PermissionKind, boolean>;

/**
 * When a user is logged in, determine the flattened set of persmissions using
 * - their assigned roles
 * - permissions for general network user
 * - default permissions (if not set by previous 2)
 *
 * Network admins don't have permissions - they can do everything.
 */
export const UserPermissionsState = withLoggedInState(
  memo(function UserPermissionsState({
    profile,
    network,
  }: HydratedLoggedInState) {
    const { setPermissions, setRoles, isNetworkAdmin } = useLoggedInState();
    console.log("UserPermissionsState", { profile, network, isNetworkAdmin });

    const { data: roles = [] } = useUserAssignedRoles(network, profile);
    const { permissions: networkPermissions } = useNetworkPermissions(network);

    const permDep = JSON.stringify(networkPermissions);
    const rolesDep = JSON.stringify(roles);

    useEffect(
      function updateNetworkPermissions() {
        const flattenedPermissionSet: NetworkPermissionSet = Object.fromEntries(
          Object.entries(basePermissionSet).map(([permission]) => {
            // networkPermissions[permission]
            const fallback = networkPermissions[permission];
            // for each permission, look up the value in all custom roles. if
            // it's enabled in any custom role that takes precedence over any
            // disabled values. if not enabled in any roles, fallback to
            // general network user permissions
            const isEnabled =
              roles.some((role) => role.permissions[permission]) || fallback;
            return [permission, isEnabled];
          })
        );
        console.log("assigned roles", {
          roles,
          flattenedPermissionSet,
          networkPermissions,
        });
        setPermissions(flattenedPermissionSet);
        setRoles(roles);
      },
      [permDep, rolesDep]
    );

    return <></>;
  }),
  { showLoader: false }
);
