import { useRouter } from "next/router";
import { useEffect } from "react";
import { useHeaderSlotStore } from "~/hooks/useHeaderSlotStore";

export function HeaderSlot() {
  const { headerSlot, clearHeaderSlot } = useHeaderSlotStore();
  const router = useRouter();
  // clear the header slot any time the url changes
  useEffect(() => {
    console.log("clearing header sot");
    clearHeaderSlot();
  }, [clearHeaderSlot, router.asPath]);

  return headerSlot;
}
