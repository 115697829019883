import { NextPageWithLayout } from "~/pages/_app";
import { LoggedInState, useLoggedInState } from "./useLoggedInState";
import { LinearProgress } from "@mui/material";

export type HydratedLoggedInState = Required<
  Pick<LoggedInState, "user" | "profile" | "network" | "isNetworkAdmin">
>;

/**
 * Ensure user, profile and network are defined before wrapping the Component
 *
 * Note: we could add an optional UI to render while loading instead of
 * rendering nothing
 */
export function withLoggedInState<P>(
  Component: React.ComponentType<P & HydratedLoggedInState>,
  { showLoader = true }: { showLoader?: boolean } = { showLoader: true }
) {
  const Wrapped = (props: P) => {
    const { user, profile, network, isNetworkAdmin } = useLoggedInState();

    if (!user || !profile || !network || isNetworkAdmin === undefined) {
      console.debug("withLoggedInState: not ready", {
        user,
        profile,
        network,
        isNetworkAdmin,
      });
      return showLoader ? <LinearProgress /> : <div />;
    }

    const loggedInProps = { user, profile, network, isNetworkAdmin };

    return <Component {...props} {...loggedInProps} />;
  };
  Wrapped.displayName = `withLoggedInState(${Component.displayName})`;
  return Wrapped;
}

/** For use with Next.js page components only
 *
 * These don't take generic props unlike regular React components.
 * If you need logged in state on a regular component use `withLoggedInState`
 * instead.
 */
export function pageWithLoggedInState(
  Component: React.ComponentType<HydratedLoggedInState>
): NextPageWithLayout {
  const Wrapped = () => {
    const { user, profile, network, isNetworkAdmin } = useLoggedInState();

    console.log("pageWithLoggedInState", {
      user,
      profile,
      network,
      isNetworkAdmin,
    });

    if (!user || !profile || !network || isNetworkAdmin === undefined) {
      return <></>;
    }

    const loggedInProps = { user, profile, network, isNetworkAdmin };

    return <Component {...loggedInProps} />;
  };
  Wrapped.displayName = `pageWithLoggedInState(${Component.displayName})`;
  return Wrapped;
}
