import { WithRef } from "@converge-collective/common/models/Base";
import {
  LiteNetwork,
  Network,
} from "@converge-collective/common/models/Network";
import { LiteProfile } from "@converge-collective/common/models/Profile";
import { CustomRole } from "@converge-collective/common/models/rbac/CustomRole";
import {
  basePermissionSet,
  NetworkPermissionSet,
  PermissionKind,
} from "@converge-collective/common/models/rbac/NetworkPermission";
import { useQuery } from "@tanstack/react-query";
import {
  collection,
  collectionGroup,
  doc,
  documentId,
  getCountFromServer,
  query,
  QueryNonFilterConstraint,
  setDoc,
  where,
} from "firebase/firestore";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
} from "reactfire";
import { converters } from "~/lib/converter";

// network sub collections and doc names
export const corePermissionsCollection = "permissionsCore";
// doc names under the custom permissions collection will be slug based
export const customPermissionsCollection = "permissionsCustom";
export const userPermissionsDoc = "userPermissions";

export const customRoleAssignmentsCollection = "customRoleAssignments";

export function useCanPerformAction() {}

export function useCustomRoles(network: WithRef<Network>) {
  const customRoles = useFirestoreCollectionData(
    collection(network.ref, customPermissionsCollection).withConverter(
      converters.customRole.read
    ),
    { initialData: [] }
  );
  return customRoles;
}

export const useCustomRoleAssignmentsCount = (
  customRole: WithRef<CustomRole>
) => {
  return useQuery({
    queryKey: ["customRoleAssignmentsCount", customRole.id],
    queryFn: async () => {
      const countQuery = collection(
        customRole.ref,
        customRoleAssignmentsCollection
      ).withConverter(converters.customRoleAssignment.read);
      const c = await getCountFromServer(countQuery);
      return c.data().count;
    },
  });
};

/** look up the assignments for the user then fetch the roles for those
 * assignments */
export const useUserAssignedRoles = (
  network?: LiteNetwork,
  profile?: LiteProfile
) => {
  const { data: assignments = [] } = useUserCustomRoleAssignments(
    network,
    profile
  );
  const firestore = useFirestore();

  const assignmentsQuery = (
    network && !isEmpty(assignments)
      ? query(
          collection(network.ref, customPermissionsCollection),
          where(
            documentId(),
            "in",
            assignments.map((a) => a.customRoleRef.id)
          )
        )
      : collection(firestore, "noop")
  ).withConverter(converters.customRole.read);

  return useFirestoreCollectionData(assignmentsQuery);
};

export const useUserCustomRoleAssignments = (
  network?: LiteNetwork,
  profile?: LiteProfile
) => {
  const firestore = useFirestore();
  const roleAssignmentsQuery = (
    network && profile
      ? query(
          collectionGroup(firestore, customRoleAssignmentsCollection),
          where("network.ref", "==", network.ref),
          where("profile.ref", "==", profile.ref)
        )
      : collection(firestore, "noop")
  ).withConverter(converters.customRoleAssignment.read);
  return useFirestoreCollectionData(roleAssignmentsQuery);
};

export const useCustomRoleAssignments = (
  customRole: WithRef<CustomRole>,
  additionalQuery?: QueryNonFilterConstraint
) => {
  const baseQuery = collection(
    customRole.ref,
    customRoleAssignmentsCollection
  ).withConverter(converters.customRoleAssignment.read);
  const collectionQuery = additionalQuery
    ? query(baseQuery, additionalQuery)
    : baseQuery;
  return useFirestoreCollectionData(collectionQuery);
};

// export async function setCustomRolePermission(
//   network: WithRef<Network>,
//   // should we call this something else?
//   roleSlug: string,
//   permissionKind: PermissionKind,
//   value: boolean
// ) {
//   // TODO
//   // const genMemberDoc = doc(
//   //   network.ref,
//   //   corePermissionsCollection,
//   //   userPermissionsDoc
//   // ).withConverter(converters.networkPermissionOverrides.write);
//   // // create the doc if it doesn't exist yet
//   // return setDoc(genMemberDoc, { [permissionKind]: value }, { merge: true });
// }

export async function setGeneralNetworkMemerPermissions(
  network: WithRef<Network>,
  permissionKind: PermissionKind,
  value: boolean
) {
  const genMemberDoc = doc(
    network.ref,
    corePermissionsCollection,
    userPermissionsDoc
  ).withConverter(converters.networkPermissionOverrides.write);
  // create the doc if it doesn't exist yet
  return setDoc(genMemberDoc, { [permissionKind]: value }, { merge: true });
}

export function useNetworkPermissions(network?: WithRef<Network>) {
  const firestore = useFirestore();
  // look up any overriden user permissions for this network
  const { data: userPermissionOverrides = {}, status } = useFirestoreDocData(
    (network
      ? doc(network.ref, corePermissionsCollection, userPermissionsDoc)
      : doc(firestore, "noop/noop")
    ).withConverter(converters.networkPermissionOverrides.read)
  );

  const memoDep = userPermissionOverrides
    ? JSON.stringify(userPermissionOverrides)
    : null;

  // merge them with the base permission set
  const permissions: NetworkPermissionSet = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(basePermissionSet).map(([k, v]) => [
          k,
          userPermissionOverrides[k] !== undefined
            ? userPermissionOverrides[k]
            : v,
        ])
      ),
    // disable eslint: we're using our own JSON representation
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memoDep]
  );
  const isLoading = status === "loading";
  return { permissions, isLoading };
}

/**
 * get the full set of user permissions for this network, falling back to
 * defaults when unset
 *
 * UPDATE: a full permission set could live in a single doc.
 * The built in roles (user permissions, group leader) could either live in a
 * separate collection so they don't conflict with custom roles, or they could
 * just be colocated.
 *
 * UPDATE: we need to look up all roles for the user and collapse them into a
 * single permission set taking into account:
 * - defualts
 * - general network member
 * - all custom roles assigned to user
 */
// TODO - store the flattened permissions in LoggedInState
// export function useUserPermissions(network: WithRef<Network>, uid: string) {
//   // determine general network member permissions are built in
//   const { data: permissions = [] } = useFirestoreCollectionData(
//     collection(network.ref, "userPermissions").withConverter(
//       converters.userPermissions.read
//     )
//   );

//   // look up any custom roles and their permission sets

//   // merge the permissions from the database with defaults. merge logic: "allow"
//   // always wins.
// }
