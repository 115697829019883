import React from "react";
import { create } from "zustand";

// Define Zustand store
export const useHeaderSlotStore = create<{
  headerSlot: React.ReactNode;
  setHeaderSlot: (content: React.ReactNode) => void;
  clearHeaderSlot: () => void;
}>((set) => ({
  headerSlot: null,
  setHeaderSlot: (content) => set({ headerSlot: content }),
  clearHeaderSlot: () => set({ headerSlot: null }),
}));
