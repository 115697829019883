import { Network } from "@converge-collective/common/models/Network";
import { Container } from "@mui/material";
import Alert from "@mui/material/Alert";
import { isEmpty } from "lodash";

export function NetworkSponsor({ network }: { network: Network }) {
  const hasSponsors = !isEmpty(network?.sponsors);
  const isBillings = network.slug === "billings";

  if (!hasSponsors && !isBillings) {
    return null;
  }

  return (
    <Container disableGutters={true} maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
      <Alert variant="outlined" severity="info" icon={false}>
        {isBillings ? (
          <span>
            Drowning in emails? Tired of repeating the same answers and
            training? Important docs getting lost? Clean up the chaos with{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.convergeworkplace.com/"
            >
              Converge Workplace
            </a>
            .
          </span>
        ) : (
          <>
            <span>{network.name} Network powered by: </span>
            {network.sponsors?.map((sponsor) => (
              <span key={sponsor.name}>
                <a href={sponsor.url} target="_blank" rel="noreferrer">
                  {sponsor.name}
                </a>{" "}
              </span>
            ))}
          </>
        )}
      </Alert>
    </Container>
  );
}
